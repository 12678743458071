import React from "react";
import { Container } from "react-bootstrap";
import headerImage from "../../Assets/header.jpg";
import logo from "../../Assets/logo-white3.png";

export default function Header() {
  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center slider"
      style={{
        background: `url(${headerImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
        maxHeight: "620px",
      }}
    >
      <img
        className="animate__animated animate__fadeIn"
        style={{ maxWidth: "100%" }}
        alt="logo"
        src={logo}
      />
    </Container>
  );
}
