import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "./";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.1",
    marginBottom: "0.5rem",
    textAlign: "center",
  },
  button: {
    background: "none",
    border: "1px solid white",
    color: "white",
    cursor: "pointer",
    textTransform: "uppercase",
    "&:hover": {
      borderColor: theme.palette.blue.primary,
      color: theme.palette.blue.primary,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: 0,
  },
}));

export default function EmailModal({ open, handleClose }) {
  const classes = useStyles();

  function submitForm(e) {
    e.preventDefault();
  }

  return (
    <Modal
      bgcolor="rgba(255, 255, 255, 0.6)"
      handleClose={handleClose}
      open={open}
    >
      <h6 className={classes.title}>
        Si deseas más información acerca de nuestros servicios, manda tus datos:
      </h6>
      <br />
      <form className={classes.form} onSubmit={submitForm}>
        <input
          type="text"
          className="form-control"
          placeholder="Nombre:"
          name="name"
        />
        <input
          type="email"
          className="form-control"
          placeholder="Email:"
          name="_replyto"
        />
        <input
          type="text"
          className="form-control"
          placeholder="Teléfono:"
          name="phone"
        />
        <div className="d-flex justify-content-center">
          <button type="submit" className={`${classes.button} btn`}>
            Enviar
          </button>
        </div>
      </form>
    </Modal>
  );
}
