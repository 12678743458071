import React from "react";
import { makeStyles } from "@mui/styles";
import { Networks } from ".";
import whatsIcon from "../Assets/Icons/wa-icon.png";
import mailIcon from "../Assets/Icons/mail-icon-blue.png";
import locationIcon from "../Assets/Icons/location-blue-icon.png";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#c3c7d0",
    textAlign: "center",
    padding: "3rem 0 2rem 0",
    "& .aviso": {
      color: theme.palette.blue.secondary,
      "&:hover": {
        color: theme.palette.blue.dark,
      },
    },
  },
  footer: {
    display: "flex",
    gap: "30px",
    height: "auto",
    paddingBottom: "2.5rem",
    textAlign: "left",
    width: "100%",
    [`@media (max-width: ${theme.b.lg})`]: {
      flexDirection: "column",
    },
    "& .networks": {
      float: "right",
      paddingRight: "30px",
      width: "100%",
      [`@media (min-width: ${theme.b.lg})`]: {
        paddingRight: "10px",
        width: "50%",
      },
    },
    "& .contact": {
      float: "left",
      gap: "10px",
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "30px",
      width: "100%",
      [`@media (min-width: ${theme.b.sm})`]: {
        paddingLeft: "80px",
      },
      [`@media (min-width: ${theme.b.lg})`]: {
        maxWidth: "400px",
        padding: 0,
        width: "50%",
      },
      "& .link": {
        color: theme.palette.blue.secondary,
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
          color: theme.palette.blue.dark,
          textDecoration: "underline",
        },
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.footer}>
        <div className="networks d-flex justify-content-end align-items-center">
          <Networks shrink={false} blue={true} />
        </div>
        <div className="contact d-flex flex-column align-items-start">
          <a className="link" href="mailto:cristales.miguel@gmail.com">
            <img
              style={{ height: "15px", paddingRight: "12px" }}
              src={mailIcon}
              alt="Mail"
            />
            cristales.miguel@gmail.com
          </a>
          <a
            className="link"
            style={{ display: "flex" }}
            href="https://goo.gl/maps/offQmBRvSvM2"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ height: "24px", padding: "5px 19px 0 4px" }}
              src={locationIcon}
              alt="Dirección"
            />
            <span>
              21 Poniente 908-1, Col. Insurgentes Chula Vista, Puebla, Puebla. C.P. 72420
            </span>
          </a>
          <a
            className="link"
            href="https://api.whatsapp.com/send?phone=2224868401"
          >
            <img
              style={{ height: "18px", paddingRight: "13px" }}
              src={whatsIcon}
              alt="WhatsApp"
            />
            2224.86.84.01
          </a>
        </div>
      </div>
      <a className="aviso" href="#servicios">
        Aviso de Privacidad
      </a>
    </div>
  );
}
