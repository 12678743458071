import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal as BootstrapModal } from "react-bootstrap";
import xIcon from "../Assets/Icons/x-circle.svg";

const useStyles = makeStyles((theme) => ({
  modal: ({ bgcolor }) => ({
    background: bgcolor,
    "& .modal-dialog": {
      maxWidth: "90vw",
      "& .modal-content": {
        width: "fit-content",
        minWidth: "50vw",
        maxWidth: "85vw",
        background: "none",
        border: "none",
        borderRadius: "0.3rem",
        margin: "0 auto",
      },
    },
  }),
  body: ({ modalBgcolor }) => ({
    background: modalBgcolor,
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "0.3rem",
    color: "white",
    padding: "2rem",
  }),
  close: {
    cursor: "pointer",
    opacity: 0.3,
    position: "absolute",
    right: "15px",
    top: "15px",
    "&:hover": {
      opacity: 1,
    },
  },
}));

export default function Modal({
  open,
  handleClose,
  bgcolor = "#015b7e99",
  modalBgcolor = "#013c68",
  children,
}) {
  const classes = useStyles({ bgcolor, modalBgcolor });
  return (
    <BootstrapModal
      centered
      className={classes.modal}
      onHide={handleClose}
      show={open}
    >
      <BootstrapModal.Body className={classes.body}>
        <img
          onClick={handleClose}
          className={classes.close}
          src={xIcon}
          alt="close"
        />
        <br />
        {children}
      </BootstrapModal.Body>
    </BootstrapModal>
  );
}
