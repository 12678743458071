import { ThemeProvider } from "@mui/styles";
import Main from "./Views/Main";
import "./CSS/animate.min.css";

const theme = {
  b: {
    xxs: "0px",
    xs: "400px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
  },
  palette: {
    blue: {
      primary: "#88b1c3",
      secondary: "#015b7e",
      light: "#dfeaee",
      dark: "#013c68",
    },
    gray: {
      primary: "#808080",
      secondary: "#84878c",
    },
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  );
}

export default App;
