import React from "react";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import logo from "../../Assets/logo-xl.png";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 auto",
    maxWidth: "1180px",
    padding: "30px 40px",
    width: "100%",
    "& .slick-next:before, & .slick-prev:before": {
      color: "#000",
      padding: "80px 0",
    },
    "& .slick-slide": {
      textAlign: "center",
    },
  },
  frame: {
    "& iframe": {
      width: "100%",
      [`@media (min-width: ${theme.b.xs})`]: {
        maxWidth: "480px",
        minHeight: "200px",
      },
      margin: "0 auto",
      padding: "0 15px",
    },
  },
  title: {
    background: `url(${logo}) no-repeat bottom`,
    color: theme.palette.gray.primary,
    padding: "4rem 0 3rem 0",
    "& h2": {
      fontSize: "1.5rem",
      textAlign: "center",
      [`@media (min-width: ${theme.b.sm})`]: {
        fontSize: "2rem",
      },
      [`@media (min-width: ${theme.b.md})`]: {
        textAlign: "start",
        paddingLeft: "6rem",
      },
    },
    "& h2:nth-child(2)": {
      [`@media (min-width: ${theme.b.md})`]: {
        paddingLeft: "9rem",
      },
    },
    "& span": {
      color: theme.palette.blue.dark,
    },
  },
}));

export default function Videos() {
  const classes = useStyles();

  const videos = [
    {
      url: "https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=https://upbeat-noether-ee0b16.netlify.app/",
      title: "Video Title",
    },
    {
      url: "https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=https://upbeat-noether-ee0b16.netlify.app/",
      title: "Video Title",
    },
    {
      url: "https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=https://upbeat-noether-ee0b16.netlify.app/",
      title: "Video Title",
    },
    {
      url: "https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=https://upbeat-noether-ee0b16.netlify.app/",
      title: "Video Title",
    },
    {
      url: "https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=https://upbeat-noether-ee0b16.netlify.app/",
      title: "Video Title",
    },
  ];

  const sliderSettings = {
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    lazyLoad: "progressive",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "100px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 924,
        settings: {
          centerPadding: "80px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 870,
        settings: {
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 778,
        settings: {
          centerPadding: "160px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 716,
        settings: {
          centerPadding: "140px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 674,
        settings: {
          centerPadding: "110px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 616,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div id="clientes-satisfechos">
      <div className={classes.title}>
        <h2>
          CLIENTES <span>SATISFECHOS</span>
        </h2>
        <h2>
          Y <span>LÍDERES</span> DE OPINIÓN
        </h2>
      </div>

      <div className={classes.container}>
        <Slider {...sliderSettings}>
          {videos.map(({ url, title }, index) => (
            <div key={index} className={classes.frame}>
              <iframe src={url} allowFullScreen frameBorder="0" title={title} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
