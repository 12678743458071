import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import facebookLogoGray from "../Assets/Icons/f-icon.png";
import facebookLogoBlue from "../Assets/Icons/f-icon-blue.png";
import linkedinLogoGray from "../Assets/Icons/in-icon.png";
import linkedinLogoBlue from "../Assets/Icons/in-icon-blue.png";
import twitterLogoGray from "../Assets/Icons/t-icon.png";
import twitterLogoBlue from "../Assets/Icons/t-icon-blue.png";
import youtubeLogoGray from "../Assets/Icons/yt-icon.png";
import youtubeLogoBlue from "../Assets/Icons/yt-icon-blue.png";
import mailLogoGray from "../Assets/Icons/mail-icon.png";
import mailLogoBlue from "../Assets/Icons/mail-icon-blue.png";

const useStyles = makeStyles((theme) => ({
  networks: {
    maxWidth: "350px",
    padding: "0 1rem 0 2rem",
    width: "100%",
  },
  link: ({ blue }) => ({
    color: blue ? theme.palette.blue.dark : theme.palette.gray.secondary,
    marginTop: "10px",
    "&:hover": {
      color: !blue ? theme.palette.blue.dark : theme.palette.gray.secondary,
    },
  }),
  icon: {
    height: "21px",
    "&.mail": {
      height: "auto",
    },
  },
  divider: {
    borderRight: "1px solid #84878c",
    height: "21px",
    width: "1px",
    "&.space": {
      margin: "0 10px",
    },
  },
}));

export default function Networks({ shrink = true, blue = false }) {
  const classes = useStyles({ blue });

  const [twitterLogo, setTwitterLogo] = useState(
    blue ? twitterLogoBlue : twitterLogoGray
  );
  const twitterHover = () =>
    setTwitterLogo(blue ? twitterLogoGray : twitterLogoBlue);
  const twitterLeave = () =>
    setTwitterLogo(!blue ? twitterLogoGray : twitterLogoBlue);

  const [facebookLogo, setFacebookLogo] = useState(
    blue ? facebookLogoBlue : facebookLogoGray
  );
  const facebookHover = () =>
    setFacebookLogo(blue ? facebookLogoGray : facebookLogoBlue);
  const facebookLeave = () =>
    setFacebookLogo(!blue ? facebookLogoGray : facebookLogoBlue);

  const [linkedinLogo, setLinkedinLogo] = useState(
    blue ? linkedinLogoBlue : linkedinLogoGray
  );
  const linkedinHover = () =>
    setLinkedinLogo(blue ? linkedinLogoGray : linkedinLogoBlue);
  const linkedinLeave = () =>
    setLinkedinLogo(!blue ? linkedinLogoGray : linkedinLogoBlue);

  const [youtubeLogo, setYoutubeLogo] = useState(
    blue ? youtubeLogoBlue : youtubeLogoGray
  );
  const youtubeHover = () =>
    setYoutubeLogo(blue ? youtubeLogoGray : youtubeLogoBlue);
  const youtubeLeave = () =>
    setYoutubeLogo(!blue ? youtubeLogoGray : youtubeLogoBlue);

  const [mailLogo, setMailLogo] = useState(blue ? mailLogoBlue : mailLogoGray);
  const mailHover = () => setMailLogo(blue ? mailLogoGray : mailLogoBlue);
  const mailLeave = () => setMailLogo(!blue ? mailLogoGray : mailLogoBlue);

  return (
    <div
      className={`${
        classes.networks
      } d-flex justify-content-center align-items-center ${
        shrink ? "justify-content-md-around" : "justify-content-around"
      }`}
    >
      <a
        href="https://www.facebook.com/miguelcristalesyconsultores/"
        target="_blank"
        rel="noreferrer"
        className={shrink ? "d-none d-md-block" : ""}
        onMouseEnter={facebookHover}
        onMouseLeave={facebookLeave}
      >
        <img src={facebookLogo} alt="Facebook" className={classes.icon} />
      </a>
      <span className={`${classes.divider} ${shrink && "d-none d-md-block"}`} />
      <a
        href="https://www.twitter.com/MACRISTALES/"
        target="_blank"
        rel="noreferrer"
        onMouseEnter={twitterHover}
        onMouseLeave={twitterLeave}
      >
        <img src={twitterLogo} alt="Twitter" className={classes.icon} />
      </a>
      <span className={`${classes.divider} ${shrink && "d-none d-md-block"}`} />
      <span
        className={`${classes.divider} d-md-none  ${
          shrink ? "d-block space" : "d-none"
        }`}
      />
      <a
        href="https://www.linkedin.com/in/miguel-%C3%A1ngel-cristales-hoyos-46416b44/"
        target="_blank"
        rel="noreferrer"
        className={shrink ? "d-none d-md-block" : ""}
        onMouseEnter={linkedinHover}
        onMouseLeave={linkedinLeave}
      >
        <img src={linkedinLogo} alt="Linkedin" className={classes.icon} />
      </a>
      <a
        href="https://www.linkedin.com/in/miguel-%C3%A1ngel-cristales-hoyos-46416b44/"
        className={shrink ? `${classes.link} d-none d-md-block` : classes.link}
        target="_blank"
        rel="noreferrer"
      >
        <span>Curriculum</span>
      </a>
      <span className={`${classes.divider} ${shrink && "d-none d-md-block"}`} />
      <a
        href="mailto:cristales.miguel@gmail.com"
        className={shrink ? "d-none d-md-block" : ""}
        onMouseEnter={mailHover}
        onMouseLeave={mailLeave}
      >
        <img src={mailLogo} alt="Mail" className={`${classes.icon} mail`} />
      </a>
      <span className={`${classes.divider} ${shrink && "d-none d-md-block"}`} />
      <a
        href="https://www.youtube.com/channel/UCWE_v8BGxXgk2wH6-h32rtQ"
        onMouseEnter={youtubeHover}
        onMouseLeave={youtubeLeave}
      >
        <img src={youtubeLogo} alt="Youtube" className={classes.icon} />
      </a>
    </div>
  );
}
