import React from "react";
import { Container, Row, Dropdown } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import Networks from "./Networks";
import menuIcon from "../Assets/Icons/hamburguer.png";
import logo from "../Assets/logo-color.png";

const useStyles = makeStyles((theme) => ({
  navRow: {
    background: "rgba(255, 255, 255, 0.5)",
  },
  menu: {
    marginLeft: "1.5rem",
    [`@media (min-width: ${theme.b.xs})`]: {
      marginLeft: "3rem",
    },
  },
  menuButton: {
    background: "none",
    border: "none",
    "& img": {
      padding: "4px",
    },
  },
  dropdownMenu: {
    background: "#e3e4e5",
    border: "none",
    maxHeight: "450px",
    overflowY: "none",
    padding: "0",
    "@media (max-height: 490px)": {
      height: "calc(100vh - 110px)",
      overflowY: "scroll",
    },
  },
  dropdownTitle: {
    color: "#244677",
    fontSize: "16px",
    marginTop: "20px",
    padding: "0 15px 7px 15px",
    textAlign: "center",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:active": {
      color: "#fff",
    },
  },
  line: {
    borderTop: "1px solid #244677",
  },
  dropdownItem: {
    padding: "1rem 1.5rem",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f8f9fa",
    },
    "&:active": {
      backgroundColor: "#007bff",
      color: "#fff",
    },
  },
}));

export default function Navbar() {
  const classes = useStyles();

  const items = [
    {
      href: "#clientes-satisfechos",
      text: (
        <>
          Clientes satisfechos <br /> y líderes de opinión
        </>
      ),
    },
    {
      href: "#aliados-estrategicos",
      text: "Aliados estratégicos",
    },
    {
      href: "#equipo-consultores",
      text: "Equipo de consultores",
    },
    {
      href: "#filosofia-empresarial",
      text: "Filosofía empresarial",
    },
  ];

  const iconToggle = React.forwardRef(({ onClick }, ref) => (
    <button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={classes.menuButton}
    >
      <img alt="menu icon" src={menuIcon} />
    </button>
  ));

  return (
    <Container fluid className="p-0">
      <Row className={`${classes.navRow} fixed-top fixed-header pt-3 pb-3 `}>
        <div className="col-5 d-flex align-items-center">
          <div className={classes.menu}>
            <Dropdown>
              <Dropdown.Toggle as={iconToggle} />
              <Dropdown.Menu className={classes.dropdownMenu}>
                <Dropdown.Item href="#servicios">
                  <h3 className={classes.dropdownTitle}>Servicios</h3>
                </Dropdown.Item>
                <div className={classes.line} />
                {items.map(({ href, text }, index) => (
                  <Dropdown.Item
                    key={index}
                    href={href}
                    className={classes.dropdownItem}
                  >
                    {text}
                  </Dropdown.Item>
                ))}
                <div className={classes.line} />
                <Dropdown.Item href="https://www.facebook.com/MACH51" className={classes.dropdownItem}>
                  Tienda virtual <br />
                  MACH 51
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center">
          <a href="https://innovacionempresarialyconsultores.com.mx">
            <img alt="logo" src={logo} height="60" />
          </a>
        </div>
        <div className="col-5 d-flex justify-content-end align-items-center">
          <Networks />
        </div>
      </Row>
    </Container>
  );
}
