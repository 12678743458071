import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "./";
import { EmailModal } from "./";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1.5",
    "& img": {
      height: "20px",
      marginLeft: "0.5rem",
      marginBottom: "0.5rem",
    },
  },
  divider: {
    opacity: 1,
    width: "200px",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "15px",
    width: "100%",
  },
  button: {
    alignSelf: "flex-end",
    background: "none",
    border: "1px solid white",
    color: "#fff",
    cursor: "pointer",
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.blue.primary,
      borderColor: theme.palette.blue.primary,
    },
  },
}));

export default function CursoModal({
  open,
  handleClose,
  children,
  title,
  icon,
}) {
  const classes = useStyles();
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  function openEmailModal() {
    setEmailModalOpen(true);
  }
  function closeModal() {
    setEmailModalOpen(false);
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <h5 className={classes.title}>
        {title} <img src={icon} alt="" />
      </h5>
      <hr className={classes.divider} />
      {children}
      <div className={classes.footer}>
        <button
          onClick={openEmailModal}
          type="button"
          className={`${classes.button} btn`}
        >
          Estoy interesado
        </button>
      </div>
      <EmailModal open={emailModalOpen} handleClose={closeModal} />
    </Modal>
  );
}
