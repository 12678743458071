import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";

import logoEMS from "../../Assets/Aliados/ems.png";
import logoFunken from "../../Assets/Aliados/funken.png";
import logoGiordano from "../../Assets/Aliados/giordano.png";
import logoInsight from "../../Assets/Aliados/insight.png";
import logoKAF from "../../Assets/Aliados/kaf.png";
import logoLantana from "../../Assets/Aliados/lantana.png";
import logoMAR from "../../Assets/Aliados/MAR.png";
import logoMDeMotivos from "../../Assets/Aliados/mDeMotivos.png";
import logoSNOVA from "../../Assets/Aliados/SNOVA.png";
import logoVectores from "../../Assets/Aliados/vectores.png";
import handsImage from "../../Assets/SVG/handshake2.svg";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    alignItems: "center",
    background:
      "linear-gradient(180deg, rgba(1,91,126,1) 0%, rgba(223,234,238,1) 100%)",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingTop: "2rem",
    [`@media (min-width: ${theme.b.md})`]: {
      paddingBottom: "2rem",
    },
    [`@media (min-width: ${theme.b.lg})`]: {
      paddingBottom: "1rem",
    },
    "& img": {
      height: "160px",
      [`@media (min-width: ${theme.b.sm})`]: {
        height: "200px",
      },
      [`@media (min-width: ${theme.b.md})`]: {
        height: "260px",
      },
    },
    "& h1": {
      color: "#fff",
      margin: 0,
      textTransform: "uppercase",
      "& span": {
        color: theme.palette.blue.dark,
      },
    },
  },
  contentContainer: {
    background: theme.palette.blue.light,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    padding: "1rem 0",
    position: "relative",
    [`@media (min-width: ${theme.b.md})`]: {
      flexDirection: "row",
    },
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "2rem 3rem 0 3rem",
    width: "100%",
    [`@media (min-width: ${theme.b.md})`]: {
      marginLeft: "1rem",
      padding: "0 3rem 1rem 2rem",
      width: "60%",
    },
    "& h3": {
      paddingRight: "2rem",
    },
    "& .text": {
      display: "flex",
      flexDirection: "column",
      paddingTop: "1rem",
      [`@media (min-width: ${theme.b.md})`]: {
        padding: "1rem 2rem 0 0",
      },
      [`@media (min-width: ${theme.b.lg})`]: {
        padding: "2rem 5rem 0 0",
      },
    },
  },
  slider: {
    display: "inline-block",
    padding: "0 40px",
    width: "100%",
    [`@media (min-width: ${theme.b.md})`]: {
      alignSelf: "start",
      padding: 0,
      width: "450px",
    },
    "& .slick-next:before, & .slick-prev:before": {
      color: "#000",
      padding: "140px 0",
    },
    "& .selected": {
      background: "#88b1c320",
      border: "1px solid #fff",
      borderRadius: "8px",
    },
  },
  element: {
    alignContent: "center !important",
    alignItems: "center !important",
    border: "1px solid #ffffff00",
    display: "flex !important",
    height: "130px",
    justifyContent: "center !important",
    margin: "10px 0",
    width: "90px",
    "& img": {
      cursor: "pointer",
      height: "100px",
      maxWidth: "80%",
      objectFit: "scale-down",
    },
  },
  button: {
    alignSelf: "end",
    background: "#88b1c355",
    border: "1px solid #88b1c355",
    borderRadius: "10px",
    fontFamily: "Nunito, sans-serif !important",
    margin: "1rem 0",
    padding: "5px",
    width: "180px",
    "&:hover": {
      background: theme.palette.blue.primary,
      color: "#fff",
    },
    "&:active": {
      background: "none",
      color: "#000",
    },
  },
}));

export default function Aliados() {
  const classes = useStyles();

  const [selected, setSelected] = useState(0);

  const aliados = [
    {
      name: "Eternal Medical SPA",
      image: logoEMS,
      body: (
        <div>
          <p>
            En <strong>Eternal Medical Spa</strong> ayudamos a mejorar el
            autoestima y la salud femenina a través de métodos no invasivos,
            novedosos y vanguardistas que nos permitan resaltar su belleza.
          </p>
          <ul>
            <li>Nuestros servicios son:</li>
            <li>
              <strong>Consultas con médico bariatra (nutrición)</strong>
            </li>
            <li>
              <strong>Masajes relajantes</strong>
            </li>
            <li>
              Faciales (hidratación , manchas, acné, marcas de acné, piel
              rosácea, disminución de líneas de expresión, rejuvenecimiento
              facial, etc)
            </li>
            <li>
              <strong>Lipoescultura sin cirugía</strong>
            </li>
            <li>Blanqueamiento de axilas</li>
            <li>Flacidez</li>
            <li>
              <strong>Depilación permanente</strong>
            </li>
            <li>Reductivo de espalda</li>
            <li>Moldeo de figura (aumento de senos y glúteo sin cirugía)</li>
            <li>Protocolo de varices</li>
            <li>Protocolo de estrías</li>
            <li>
              <strong>Protocolos Post Parto</strong>
            </li>
            <li>
              <strong>Day Spa</strong>
            </li>
            <li>Uñas</li>
            <li>Reductivo de papada</li>
            <li>Reductivo de brazo</li>
            <li>Fortalecimiento capilar</li>
            <li>Protocolo vs la celulitis</li>
            <li>Osteopatía</li>
            <li>
              <strong>Paquetes de novia</strong>
            </li>
          </ul>
        </div>
      ),
    },
    {
      name: "Funken - Estudio de dieño",
      image: logoFunken,
      body: (
        <div>
          <p>
            Somos un estudio de diseño listo para conectar tu negocio con tu
            audiencia a través de:
          </p>
          <ul>
            <li>
              Desarrollo de marca (identidad visual, etiquetas, aplicaciones)
            </li>
            <li>Diseño editorial (manuales, folletos, displays)</li>
            <li>Diseño de información (infografías, iconografía)</li>
            <li>Ilustración</li>
            <li>Fotografía de producto</li>
          </ul>
        </div>
      ),
    },
    {
      name: "Giordano's Estudio de Baile",
      image: logoGiordano,
      body: (
        <div>
          <p>En Giordano´s Estudio de Baile tenemos 6 tipos de cursos:</p>
          <ol>
            <li>Trimestrales (cursos regulares)</li>
            <li>Clase abierta (pago por clase)</li>
            <li>Intensivos</li>
            <li>Particulares</li>
            <li>Grupos empresariales</li>
            <li>Coreografías para eventos</li>
          </ol>
          <p>Pregunta por nuestra garantía de satisfacción total.</p>
        </div>
      ),
    },
    {
      name: "In Sight Solutions – automatización de casas",
      image: logoInsight,
      body: (
        <div>
          <p>
            Empresa dedicada a la automatización de tu casa u oficina desde
            aplicaciones instaladas en tu teléfono (audio, cine, luces, cámaras
            de vigilancia, gas, etc.)
          </p>
        </div>
      ),
    },
    {
      name: "Kaf Media",
      image: logoKAF,
      body: (
        <div>
          <p>Empresa de video vigilancia y rastreo satelital</p>
        </div>
      ),
    },
    {
      name: "Lantana Eventos",
      image: logoLantana,
      body: (
        <div>
          <p>
            En LANTANA nos dedicamos a la planeación y organización de eventos
            con la sensibilidad de cuidar hasta el mínimo detalle.
          </p>
          <p>Nuestros Servicios son:</p>
          <ul>
            <li>Wedding Planner</li>
            <li>Event planner</li>
            <li>Event consulting</li>
            <li>Gestión y negociación de proveedores para evento</li>
            <li>Coordinación del día</li>
            <li>Coordinación de producciones</li>
            <li>Seguimiento de proyectos</li>
            <li>Event consulting</li>
            <li>Confirmación de invitados</li>
            <li>HoneyMoon / Graduation plan trip</li>
          </ul>
        </div>
      ),
    },
    {
      name: "MAR estudio - Branding & Diseño",
      image: logoMAR,
      body: (
        <div>
          <p>
            Estudio de diseño al servicio de PyMEs y particulares, que desean
            posicionarse en el mercado con ideas innovadoras basadas en
            conceptos claros y creativos.
          </p>
          <ul>
            <li>Branding</li>
            <li>Etiquetas</li>
            <li>Promocionales</li>
            <li>Consultoría en Marcas</li>
          </ul>
        </div>
      ),
    },
    {
      name: "M de Motivos - Repostería Fina",
      image: logoMDeMotivos,
      body: (
        <div>
          <p>
            Repostería fina dedicada a la elaboración de pasteles, galletas y
            postres selectos en presentaciones diversas:
          </p>
          <ul>
            <li>Pasteles personalizados</li>
            <li>Brownies por pieza</li>
            <li>Galletas Melties por Kg.</li>
            <li>Galletas suspiro por pieza</li>
            <li>Galletas suspiro por caja (30 pzas)</li>
            <li>Vasito de Pastel 3 leches</li>
            <li>Vasito de Tiramisú</li>
            <li>Muffin de pastel de zanahoria</li>
            <li>Pastel de zanahoria completo</li>
            <li>Muffin de pastel de plátano</li>
            <li>Pastel de plátano completo</li>
            <li>Panqué de Naranja</li>
            <li>Strudel de Manzana</li>
            <li>Rústico de Manzana</li>
            <li>Mostachón de Fresas</li>
            <li>Panqué de Café con Choc. Oaxaqueño</li>
            <li>Cupcakes de sabores variados</li>
            <li>Galletas decoradas personalizadas</li>
            <li>Cheescake</li>
            <li>Galletas de nuez por Kg.</li>
            <li>Crumble de manzana</li>
          </ul>
          <p>
            Pedidos con 3 a 10 días de anticipación (dependiendo del tipo de
            pedido). Personalizamos tu pedido tal cual lo requieras.
          </p>
        </div>
      ),
    },
    {
      name: "Snova – Soluciones tridimensionales en impresiones 3D",
      image: logoSNOVA,
      body: (
        <div>
          <p>
            En <strong>Snova – Soluciones tridimensionales</strong> nos
            dedicamos a realizar:
          </p>
          <p>
            <strong>Impresión y modelado 3D</strong>
          </p>
          <p>
            La excelente calidad de nuestro servicio de manufactura aditiva y
            diseño, en tiempos reducidos, volverá tu proceso de innovación mas
            ágil y acelerará la productividad de tu empresa con costos
            reducidos.
          </p>
          <p>
            <strong>Desarrollo e innovación en productos o prototipos</strong>
          </p>
          <p>
            Nuestro departamento de innovación e ingeniería te ayudará a llevar
            tus ideas de producto, a la realidad, acercarte a tu merca- do y
            validar tus productos en tiempos más reducidos.
          </p>
          <p>
            <strong>Talleres y asesorías</strong>
          </p>
          <p>
            Buscamos acercar a tu empresa a la tecnología 3D con talleres,
            asesorías y montaje de laboratorios de impresión 3D para que tengas
            una experiencia diferente de producción reduciendo tiempos y costos
            de prototipado.
          </p>
        </div>
      ),
    },
    {
      name: "Vectores Las",
      image: logoVectores,
      body: (
        <div>
          <p>
            Servicios profesionales de Ingeniería Topográfica, Geodesia,
            Batimetría, Mapeo móvil, Fotogrametría, Cartografía con lidar y
            escaneo 3D. Servicios en toda la república mexicana.
          </p>
        </div>
      ),
    },
  ];

  const sliderSettings = {
    arrows: true,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    rows: 5,
    slidesPerRow: 1,
    slidesToShow: 2,
    speed: 200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          rows: 2,
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          rows: 1,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerMode: false,
          rows: 1,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 390,
        settings: {
          centerMode: false,
          rows: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 260,
        settings: {
          centerMode: false,
          rows: 1,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={classes.titleContainer} id="aliados-estrategicos">
        <h1>
          Aliados <br />
          <span>Estratégicos</span>
        </h1>
        <img alt="" src={handsImage} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.slider}>
          <Slider {...sliderSettings}>
            {aliados.map(({ name, image }, index) => (
              <div
                key={index}
                onClick={() => setSelected(index)}
                className={`${classes.element} ${
                  index === selected && "selected"
                }`}
              >
                <img alt={name} src={image} />
              </div>
            ))}
          </Slider>
        </div>
        <div className={classes.info}>
          <h3>{aliados[selected].name}</h3>
          <div className="text">
            {aliados[selected].body}
            <button className={classes.button}>Estoy interesado</button>
          </div>
        </div>
      </div>
    </>
  );
}
