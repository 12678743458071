import React from "react";
import { makeStyles } from "@mui/styles";
import teamImage from "../../Assets/SVG/team.svg";
import quoteImage from "../../Assets/texto.png";
import logo from "../../Assets/logo-xl.png";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    background: `url(${logo}) no-repeat top right`,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "space-around",
    height: "400px",
    padding: "2rem 0",
    [`@media (min-width: ${theme.b.sm})`]: {
      gap: 0,
      height: "600px",
    },
    [`@media (min-width: ${theme.b.md})`]: {
      flexDirection: "row",
    },
    "& .quote": {
      height: "120px",
      maxWidth: "100%",
      [`@media (min-width: ${theme.b.sm})`]: {
        height: "160px",
      },
    },
    "& .team": {
      height: "150px",
      maxWidth: "100%",
      [`@media (min-width: ${theme.b.sm})`]: {
        height: "260px",
      },
      [`@media (min-width: ${theme.b.md})`]: {
        height: "210px",
      },
      [`@media (min-width: ${theme.b.lg})`]: {
        height: "300px",
      },
    },
  },
}));
export default function Banner() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img alt="" className="quote" src={quoteImage} />
      <img alt="" className="team" src={teamImage} />
    </div>
  );
}
