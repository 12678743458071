import React, { useState } from "react";
import { CursoModal } from "../../Components";
import plantaIcon from "../../Assets/SVG/plant.svg";
import industriaIcon from "../../Assets/SVG/empresa.svg";
import viveroIcon from "../../Assets/SVG/vivero.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.blue.secondary,
    padding: 0,
    width: "100%",
    [`@media (min-width: ${theme.b.sm})`]: {
      padding: "0 3rem",
    },
    [`@media (min-width: ${theme.b.md})`]: {
      padding: "0 5rem",
    },
    [`@media (min-width: ${theme.b.lg})`]: {
      padding: "0 3rem",
    },
  },
  card: {
    color: "#fff",
    cursor: "pointer",
    padding: "3rem 0",
    transition: "background 0.1s ease-in",
    [`@media (min-width: ${theme.b.lg})`]: {
      "&:nth-child(2)": {
        borderLeft: "1px solid rgba(255,255,255,0.5)",
        borderRight: "1px solid rgba(255,255,255,0.5)",
      },
      padding: "4rem 0",
    },
    "& .cardContainer": {
      margin: "0 auto",
      maxWidth: "300px",
      paddingTop: "1rem",
      textAlign: "center",
      "& .text": {
        [`@media (min-width: ${theme.b.lg})`]: {
          padding: "0 2rem",
          paddingTop: "1rem",
          width: "100%",
        },
      },
    },
    "& .title": {
      textTransform: "uppercase",
    },
    "& img": {
      margin: "2rem 0",
      opacity: 0.5,
      transition: "opacity 0.1s ease-in",
    },
    "&:hover": {
      background: theme.palette.blue.dark,
      "& img": {
        opacity: 1,
      },
    },
  },
  modal: {
    "& .container": {
      display: "flex",
      flexWrap: "wrap",
      gap: "20px",
      padding: "1rem 0",
      [`@media (min-width: ${theme.b.lg})`]: {
        gap: "40px",
      },
      "& div": {
        width: "380px",
      },
    },
    "& p": {
      margin: "2px",
    },
  },
}));

export default function Servicios() {
  const classes = useStyles();

  const info = [
    {
      name: "startup",
      title: "StartUp",
      description:
        "Servicios para emprendedores que están en etapa de idea y desean iniciar un proyecto o adquirir nuevas habilidades y conocimientos.",
      icon: plantaIcon,
      body: (
        <div className={classes.modal}>
          <p>
            Talleres, cursos y capacitaciones de 2 hrs hasta 20hrs. Los temas
            con los que contamos son:
          </p>
          <div className="container">
            <div>
              <p>
                <strong>Taller:</strong> Emprendedor estratégico
              </p>
              <p>
                <strong>Curso: </strong>La imagen del éxito
              </p>
              <p>
                <strong>Curso: </strong>El poder de la Palabra
              </p>
              <p>
                <strong>Taller:</strong> ¿Cómo volver más atractivo mi local?
              </p>
              <p>
                <strong>Taller:</strong> ¿Cómo conseguir recursos del gobierno
                para mi empresa?
              </p>
              <p>
                <strong>Taller:</strong> Programa de Incubación en línea del
                INADEM{" "}
              </p>
            </div>
            <div>
              <strong>Conferencias de 1 a 2 hrs:</strong>
              <p>
                <strong>Curso: </strong>La imagen del éxito
              </p>
              <p>
                <strong>Curso: </strong>Mi Plan de vida y mi empresa
              </p>
              <p>
                <strong>Curso: </strong>Financiamientos a mi Alcance
              </p>
              <p>
                <strong>Curso: </strong>Mujer independiente, mujer poderosa
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "vivero",
      title: "Vivero de Empresas",
      description:
        "Acompañamiento de emprendedores con prototipos realizados y a punto de iniciar una empresa o de microempresarios con una apertura.",
      icon: viveroIcon,
      body: (
        <div className={classes.modal}>
          <strong>20 hrs de fortalecimiento psicológico</strong>
          <div className="container">
            <div>
              <strong>Capacitación en:</strong>
              <ul>
                <li>Modelos de negocios</li>
                <li>Administración y personal</li>
                <li>Producción o comercialización</li>
                <li>Mercadotecnia</li>
                <li>Finanzas</li>
                <li>Ventas</li>
                <li>Jurídico</li>
              </ul>
            </div>
            <div>
              <strong>Otros servicios:</strong>
              <br />
              <ul>
                <li>Integración de Modelos de Negocios</li>
                <li>Integración de Plan de Negocios</li>
                <li>Networkings (2 al año)</li>
                <li>Club de empresarios e inversionistas</li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "empresas",
      title: "Medianas y grandes empresas",
      description:
        "Servicios destinados a la Mediana y Gran Empresa para atender el fondeo de Proyectos específicos, la Capacitación In situ de su personal y soluciones a la medida de problemas específicos.",
      icon: industriaIcon,
      body: (
        <div className={classes.modal}>
          <div className="container">
            <div>
              <strong>
                Proyectos Específicos para obtener financiamiento federal:
              </strong>
              <p>
                Diagnóstico (Visita física, buró de crédito y situación fiscal)
              </p>
              <p>
                Realización del Proyecto para obtener Financiamiento Federal
              </p>
              <p>
                Integración de solicitud y expediente (incluye seguimiento a
                posibles observaciones)
              </p>
              <p>
                Trámites para depósito del monto federal (orientación con
                respecto a los requisitos para la apertura de la cuenta y la
                ministración del recurso federal)
              </p>
              <p>
                Comprobación del recurso (siempre y cuando el cliente
                proporcione la información y documentación en tiempo y forma)
              </p>
              <br />
              <strong>Capacitación In situ:</strong>
              <p>Mejorando mi servicio de atención a clientes - 8 hrs</p>
              <p>
                Replanteando mi filosofía institucional (Misión, Visión, Valores
                y Propuesta de valor) - 8 hrs
              </p>
            </div>
            <div>
              <strong>Otros servicios:</strong>
              <p>Integración de Modelos de Negocios</p>
              <p>Integración de Plan de Negocios</p>
              <p>Networkings (2 al año)</p>
              <p>Club de empresarios e inversionistas</p>
              <br />
              <strong>Soluciones a la medida en:</strong>
              <ul>
                <li>Software</li>
                <li>APPs</li>
                <li>Diseño</li>
                <li>Auditorías financiaeras</li>
                <li>Contabilidad</li>
                <li>Reclutamiento y selección</li>
                <li>Sericios de carpinteriá</li>
                <li>Automatización de espacios</li>
                <li>Etc.</li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [modalOpen, setModalOpen] = useState({
    startup: false,
    vivero: false,
    empresas: false,
  });

  function openModal(name) {
    setModalOpen({ ...modalOpen, [name]: true });
  }
  function closeModal(name) {
    setModalOpen({ ...modalOpen, [name]: false });
  }

  function Card({ name, description, title, icon }) {
    return (
      <div
        onClick={() => openModal(name)}
        className={`${classes.card} col-12 col-lg-4`}
      >
        <div className="cardContainer">
          <h5 className="title">{title}</h5>
          <img alt="" src={icon} />
          <div className="text">{description}</div>
        </div>
      </div>
    );
  }

  return (
    <div id="servicios">
      <div className={classes.container}>
        <div className="d-lg-flex">
          {info.map((element, index) => (
            <Card
              key={index}
              name={element.name}
              title={element.title}
              description={element.description}
              icon={element.icon}
            />
          ))}
        </div>
        {info.map((element, index) => (
          <CursoModal
            key={index}
            handleClose={() => closeModal(element.name)}
            open={modalOpen[element.name]}
            title={element.title}
            icon={element.icon}
          >
            {element.body}
          </CursoModal>
        ))}
      </div>
    </div>
  );
}
