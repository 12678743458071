import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import teamImage from "../../Assets/team.jpg";

const useStyles = makeStyles((theme) => ({
  filosofia: {
    background: `url(${teamImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "auto",
    position: "relative",
    [`@media (max-width: ${theme.b.sm})`]: {
      minHeight: "500px",
    },
    [`@media (min-width: ${theme.b.sm})`]: {
      height: "480px",
    },
    [`@media (min-width: ${theme.b.md})`]: {
      height: "510px",
    },
  },
  container: {
    maxWidth: "90%",
    margin: "0 auto",
    padding: "0 15px",
    width: "100%",
    "& .blue-half": {
      zIndex: "1 !important",
      position: "absolute",
      top: "0",
      height: "100%",
    },
    "& .blue-half-1": {
      left: 0,
      width: "50%",
      background: "rgba(161, 195, 208,0.5)",
    },
    "& .blue-half-2": {
      right: 0,
      width: "100%",
      background: "rgba(0, 91, 127, 0.5)",
      [`@media (min-width: ${theme.b.sm})`]: {
        width: "50%",
      },
    },
  },
  title: {
    color: "#fff",
    [`@media (min-width: ${theme.b.sm})`]: {
      color: theme.palette.blue.dark,
    },
  },
  content: {
    position: "relative",
    zIndex: "2 !important",
    "& .options": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      fontFamily: "Cinzel, serif",
      textAlign: "center",
      textTransform: "uppercase",
      [`@media (min-width: ${theme.b.sm})`]: {
        gap: "10px",
      },
      "& p": {
        color: "#fff",
        cursor: "pointer",
        paddingBottom: "2px",
        "&:hover": {
          color: theme.palette.blue.dark,
        },
      },
      "& .selected": {
        borderBottom: `1px solid ${theme.palette.blue.dark}`,
        color: theme.palette.blue.dark,
      },
    },
    "& .text": {
      color: "#fff",
      padding: "2rem 2rem 0 2rem",
      [`@media (min-width: ${theme.b.sm})`]: {
        padding: "0 3rem 0 2rem",
        "& ul": {
          transform: "translateY(-50px)",
        },
        "& p": {
          transform: "translateY(-10px)",
        },
      },
      [`@media (min-width: ${theme.b.md})`]: {
        padding: "0 3rem 0 4rem",
      },
      [`@media (min-width: ${theme.b.lg})`]: {
        padding: "0 6rem",
      },
    },
  },
}));

export default function Filosofia() {
  const classes = useStyles();
  const [selected, setSelected] = useState(1);

  const info = [
    {
      name: "Misión",
      text: (
        <p>
          Ayudar a los empresarios a crear, consolidar o mejorar sus
          organizaciones con resultados precisos y visibles.
        </p>
      ),
    },
    {
      name: "Visión 2022",
      text: (
        <p>
          Innovación Empresarial y Consultores es el despacho que tiene el mayor
          reconocimiento y el mayor número de financiamientos obtenidos en el
          Estado de Puebla.
        </p>
      ),
    },
    {
      name: "Propuesta de valor",
      text: (
        <p>
          ¡Porque creemos que algunas locuras cambian al mundo, creemos en ti,
          cristalizamos tus sueños!
        </p>
      ),
    },
    {
      name: "Valores",
      text: (
        <div>
          <ul className="d-none d-sm-block">
            <li>Honestidad</li>
            <li>Pasión</li>
            <li>Transparencia</li>
            <li>Innovación</li>
            <li>Trascendencia</li>
            <li>Profesionalismo</li>
            <li>Liderazgo</li>
            <li>Estrategia</li>
            <li>Trabajo en equipo</li>
            <li>Enfoque de resultados</li>
          </ul>
          <p className="d-block d-sm-none">
            Honestidad, Pasión, Transparencia, Innovación, Trascendencia,
            Profesionalismo, Liderazgo, Estrategia, Trabajo en equipo, Enfoque
            de resultados.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div
      className={`${classes.filosofia} pt-4 pb-4`}
      id="filosofia-empresarial"
    >
      <div className={classes.container}>
        <div className="blue-half blue-half-1 d-none d-sm-block" />
        <div className="blue-half blue-half-2" />
        <Row className={`${classes.content} mt-4 mt-md-5`}>
          <div className={`wow fadeIn mb-2 mb-sm-5 pb-3 ${classes.title}`}>
            <h1>FILOSOFÍA</h1>
            <h1 style={{ paddingLeft: "3rem" }}>EMPRESARIAL</h1>
          </div>
          <div className="col-12 col-sm-6">
            <div className="options">
              {info.map(({ name }, id) => (
                <p
                  key={id}
                  className={selected === id ? "selected" : ""}
                  onClick={() => setSelected(id)}
                >
                  {name}
                </p>
              ))}
            </div>
          </div>
          <div className="col-12 col-sm-6 text">{info[selected].text}</div>
        </Row>
      </div>
    </div>
  );
}
