import React from "react";
import {
  Header,
  Servicios,
  Filosofia,
  Videos,
  Equipo,
  Aliados,
  Banner,
} from "./Sections";
import { Navbar, Footer } from "../Components";

export default function Main() {
  return (
    <>
      <Navbar />
      <Header />
      <Banner />
      <Servicios />
      <Aliados />
      <Equipo />
      <Filosofia />
      <Footer />
    </>
  );
}
