import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import AlejandroPadilla from "../../Assets/Miembros/AlejandroPadilla.jpg";
import CristinaReinosa from "../../Assets/Miembros/CristinaReinosa.jpg";
import GloriaSoancatl from "../../Assets/Miembros/GloriaSoancatl.jpg";
import MarianaMassiel from "../../Assets/Miembros/MarianaMassiel.jpg";
import MiguelCristales from "../../Assets/Miembros/MiguelCristales.jpg";
import XochiquetzalliQuiroz from "../../Assets/Miembros/XochiquetzalliQuiroz.jpg";
import logo from "../../Assets/logo-xl.png";

const members = [
  {
    name: "Ing. Alejandro Padilla Uribe",
    text: "Consultor en Producción y/o Comercialización",
    picture: AlejandroPadilla,
  },

  {
    name: "Mtra. Cristina Reinosa",
    text: "Consultora en Mercadotecnia y Ventas",
    picture: CristinaReinosa,
  },
  {
    name: "Mtra. Xóchitlquetzalli Quiróz",
    text: "Consultor Jurídico",
    picture: XochiquetzalliQuiroz,
  },
  {
    name: "Mtra. Gloria Soancatl Mendoza",
    text: "Consultor en Finanzas",
    picture: GloriaSoancatl,
  },
  {
    name: "Lic. Mariana Massiel",
    text: "Consultor en Diseño e Imagen Corporativa",
    picture: MarianaMassiel,
  },
  {
    name: "Mtro. Miguel Ángel Cristales Hoyos",
    text: "Consultor en Modelo de Negocios y Administración",
    picture: MiguelCristales,
  },
];

const useStyles = makeStyles((theme) => ({
  div: {
    height: "auto",
    paddingTop: "2rem",
    background: `url(${logo}) no-repeat center right`,
  },
  container: {
    minHeight: "460px",
    [`@media (min-width: ${theme.b.md})`]: {},
    [`@media (min-width: ${theme.b.lg})`]: {
      minHeight: "520px",
    },
  },
  infoContainer: {
    width: "auto",
    padding: "1rem",
    textAlign: "center",
    color: theme.palette.blue.dark,
    [`@media (min-width: ${theme.b.md})`]: {
      paddingRight: 0,
      textAlign: "right",
      "& .name": {
        marginBottom: "1.5rem",
      },
    },
    "& .name": { fontSize: "1.5rem" },
    "& .description": { fontSize: "1.2rem" },
  },
  slider: {
    padding: "40px",
    paddingTop: "10px",
    maxHeight: "410px",
    maxWidth: "420px",
    margin: "0 auto",
    [`@media (min-width: ${theme.b.md})`]: {
      margin: 0,
      float: "right",
      width: "450px",
      height: "450px",
      maxHeight: "none",
      maxWidth: "none",
    },
    [`@media (min-width: ${theme.b.lg})`]: {
      width: "500px",
      height: "520px",
      paddingTop: "20px",
    },
    "& .slick-next:before, & .slick-prev:before": {
      color: "#000",
      padding: "140px 0",
    },
    "& .slick-slide": {
      padding: "0 5px",
      [`@media (min-width: ${theme.b.sm})`]: {
        padding: "0 10px",
      },
    },
  },
  sliderContainer: {
    [`@media (min-width: ${theme.b.lg})`]: {
      width: "50%",
      float: "right",
      display: "flex",
      justifyContent: "center",
      overflowX: "clip",
    },
  },
  profilePicture: {
    width: "100%",
    padding: "0 5px",
    marginBottom: "10px",
    objectFit: "cover",
    objectPosition: "center",
    [`@media (min-width: ${theme.b.sm})`]: {
      padding: 0,
      margin: "0 0 10px 10px",
      height: "320px",
      width: "320px",
    },
    [`@media (min-width: ${theme.b.md})`]: {
      width: "350px",
      height: "350px",
    },
    [`@media (min-width: ${theme.b.lg})`]: {
      width: "400px",
      height: "400px",
    },
  },
  profileThumb: {
    width: "50px",
    height: "50px",
    objectFit: "cover",
    objectPosition: "center",
    cursor: "pointer",
  },
  title: {
    color: theme.palette.gray.primary,
    margin: "2rem",
    textAlign: "center",
    [`@media (min-width: ${theme.b.md})`]: {
      textAlign: "left",
      margin: "1.5rem 3rem 4rem 4rem",
    },
    "& span": {
      color: theme.palette.blue.dark,
      [`@media (min-width: ${theme.b.lg})`]: {
        marginLeft: "1.5rem",
      },
    },
  },
}));

export default function Equipo() {
  const classes = useStyles();

  const [memberIndex, setMemberIndex] = useState(0);

  const settingsThumbs = {
    beforeChange: (current, next) => {
      setMemberIndex(next);
    },
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "1px",
    autoplay: true,
    autoplaySpeed: 3000,
    lazyLoading: "ondemand",
    responsive: [
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <div className={classes.div} id="equipo-consultores">
      <h1 className={`${classes.title} d-md-none`}>
        EQUIPO DE <span>CONSULTORES</span>
      </h1>
      <div className={classes.container}>
        <div className={classes.sliderContainer}>
          <div className={classes.slider}>
            <img
              alt={members[memberIndex].name}
              className={classes.profilePicture}
              src={members[memberIndex].picture}
            />
            <Slider {...settingsThumbs}>
              {members.map(({ picture, name }, index) => (
                <img
                  key={index}
                  alt={name}
                  className={classes.profileThumb}
                  src={picture}
                />
              ))}
            </Slider>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <h1 className={`${classes.title} d-none d-md-block`}>
            EQUIPO DE <br />
            <span>CONSULTORES</span>
          </h1>
          <p className="name">{members[memberIndex].name}</p>
          <p className="description">{members[memberIndex].text}</p>
        </div>
      </div>
    </div>
  );
}
